@import "../../styles/_variables";
.landing_container {
  .landing_background {
    opacity: 0.8;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 95vh;
    display: flex;
    justify-content: flex-end;
    &[dir="ltr"] {
      transform: scaleX(-1);
      right: 0 !important;
      left: auto;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    .title {
      margin-top: 100px;
      h1 {
        font-size: 40px;
      }
      h2 {
        white-space: pre-line;
        font-size: 36px;
        color: var(--theme-dark);
      }
    }
  }
  .abilities_container {
    @include custom-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .icon_box_container {
      @include flex-center;
      flex-direction: column;
      gap: 10px;
      label {
        font-weight: bold;
        font-size: var(--font-size-medium);
      }
      .icon_box {
        font-size: var(--font-size-large);
        @include flex-center;
        border-radius: 15px;
        width: 50px;
        height: 50px;
        background-color: #eff4fe;
        color: #193152;
      }
    }
  }
}

@media (max-width: "674px") {
  .landing_container {
    .landing_background {
      left: -155px;
      opacity: 0.5;
    }
    .main {
      .title {
        margin-top: 50px;
        h1 {
          font-size: 20px;
        }
        h2 {
          font-size: 14px;
        }
      }
    }
    .abilities_container {
      align-items: flex-start;
      .icon_box_container {
        width: 50px;
        label {
          font-size: var(--font-size-x-small);
        }
        .icon_box {
          font-size: var(--font-size-medium);
          width: 34px;
          height: 34px;
        }
      }
    }
  }
}
