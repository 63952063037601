@import "../../styles/variables";

.btn_classes {
    width: 100%;
    background-color: var(--primary);
    padding: 4px 7px;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    color: var(--white);
    border-color: var(--white);
    &:hover{
        transition: all 0.3s ;
        background-color: var(--white);
        color: var(--primary);
        border-color: var(--primary);
    }
}
.btn_classes_secondary {
    width: 100%;
    background-color: var(--secondary);
    padding: 4px 7px;
    border: 1px solid var(--primary);
    border-radius: 5px;
    color: var(--white);
    border-color: var(--white);
    &:hover{
        transition: all 0.3s ;
        background-color: var(--white);
        color: var(--secondary);
        border-color: var(--secondary);
    }
}

.disabled{
    opacity: 0.5;
    border: none;
    
}