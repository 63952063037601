@import "../../styles/_variables";
.navbar_container {
  font-size: var(--font-size-small);
  a {
    width: fit-content;
    padding: 5px;
    font-weight: bold;
    border: 2px solid transparent;
    &:hover {
      transition: 0.5s;
     color: var(--info);
    }
  }
  .mobile_navbar {
    display: flex;
    flex-direction: column;
    display: none;
    position: relative;
    .menu {
      transition: 0.2s;
      position: fixed;
      left: 0;
      top: 38px;
      &[dir="rtl"] {
        right: 0;
      }
      z-index: 2;
      padding: 12px;
      width: 70vw;
      height: 100vh;
      background-color: var(--white);

      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .menu_shadow {
      transition: 0.2s;
      position: fixed;
      top: 38px;
      z-index: 1;
      right: 0;
      &[dir="rtl"] {
        left: 0;
        right: auto;
      }
      height: 100vh;
      width: 30vw;
      @include blur;
      background-color: rgba(0, 0, 0, 0.457);
    }
    .none_menu {
      transition: 0.2s;
      left: -70vw !important;
      top: 38px !important;
      &[dir="rtl"] {
        right: -70vw !important;
      }
    }
    .none_shadow {
      transition: 0.2s;
      right: -30vw !important;
      top: 38px !important;
      &[dir="rtl"] {
        left: -30vw !important;
      }
    }

    button {
      border: 1px solid transparent;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap: 3px;
      div {
        height: 3px;
        border-radius: 35px;
        width: 10px;
        background-color: black;
      }
      &:active {
        background-color: var(--blue-50);
      }
    }
    .active {
      div {
        transition: 0.2s;
        width: 20px;
      }
    }
    .inactive {
      div {
        &:first-child {
          width: 20px;
        }
        &:nth-child(2) {
          width: 8px;
        }
        &:last-child {
          width: 12px;
        }
        transition: 0.2s;
      }
    }
  }
}
@media (max-width: 674px) {
  .navbar_container {
    .mobile_navbar {
      display: flex;
    }
    .window_navbar {
      display: none;
    }
  }
}
