@import "../../../styles/_variables";
.footer_container {
  position: relative;
  margin-top: 48px;
  padding: 24px 48px;
  background-color: var(--primary);
  color: var(--white);
  a {
    &:hover {
      color: white;
    }
    font-size: var(--font-size-small);
  }
  .contact_us_container {
    padding: 12px;
    border-radius: 4px;
    background-color: var(--secondary);
  }
  .scroll_top_btn {
    cursor: pointer;
    position: absolute;
    right: 18%;
    top: -31px;
    width: 32px;
    height: 32px;
    color: var(--primary);
  }
}
@media (max-width: 674px) {
  .footer_container {
    padding: 24px 12px;
  }
}
