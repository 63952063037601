@import "../../styles/variables";
.layout_container {
  padding: 48px;
}
.dropdown_item {
  @include button--striped;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px;
  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
}
.navbar_container {
  z-index: 100;
  width: 100vw;
  border-bottom: 1px solid var(--theme-dark);
  background-color: var(--theme-light);
  position: fixed;
  padding: 12px 48px;
  top: 0;
  display: flex;
  gap: 18px;
  .active_link {
    color: var(--primary);
  }
}
@media (max-width: "674px") {
  .layout_container {
    padding: 14px 18px;
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
  .navbar_container {
    background-color: var(--white);
    width: 100vw;
    padding: 5px 12px;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    gap: 5px;
    border-bottom: 1px solid rgb(0 0 0 / 15%);
  }
}
