@font-face {
  font-family: iran-sans;
  font-style: normal;
  font-weight: normal;
  src: url("../../public/fonts/iran-sans/IRAN\ Sans\ Regular\ \(mmrostami.blog.ir\).ttf")
    format("truetype");

  font-display: swap;
}
@font-face {
  font-family: iran-sans;
  font-style: normal;
  font-weight: bold;
  src: url("../../public/fonts/iran-sans/IRAN\ Sans\ Bold\ \(mmrostami.blog.ir\).ttf")
    format("truetype");
}

@font-face {
  font-family: mona-sans;
  font-style: normal;
  font-weight: normal;
  src: url("../../public/fonts/mona-sans/Mona-Sans-Regular.woff")
    format("truetype");
}

@font-face {
  font-family: mona-sans-semibold;
  font-style: normal;
  src: url("../../public/fonts/mona-sans/Mona-Sans-SemiBold.woff")
    format("truetype");
}

@font-face {
  font-family: mona-sans-bold;
  font-style: normal;
  src: url("../../public/fonts/mona-sans/Mona-Sans-Bold.woff")
    format("truetype");
}

@font-face {
  font-family: iran-sans-bolder;
  font-style: normal;
  font-weight: bolder;
  src: url("../../public/fonts/mona-sans/Mona-Sans-Black.woff")
    format("truetype");
}
@font-face {
  font-family:shabnam;
  font-style: normal;
  font-weight: bold;
  src: url("../../public/fonts/shabnam/Shabnam-Bold-FD.eot");
  src: url("../../public/fonts/shabnam/Shabnam-Bold-FD.woff2") format("woff2"),
    url("../../public/fonts/shabnam/Shabnam-Bold-FD.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: shabnam;
  font-style: normal;
  font-weight: 500;
  src: url("../../public/fonts/shabnam/Shabnam-Medium-FD.eot");
  src: url("../../public/fonts/shabnam/Shabnam-Medium-FD.woff2") format("woff2"),
    url("../../public/fonts/shabnam/Shabnam-Medium-FD.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: shabnam;
  font-style: normal;
  font-weight: normal;
  src: url("../../public/fonts/shabnam/Shabnam-FD.eot");
  src: url("../../public/fonts/shabnam/Shabnam-FD.woff2") format("woff2"),
    url("../../public/fonts/shabnam/Shabnam-FD.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: shabnam;
  font-style: normal;
  font-weight: 300;
  src: url("../../public/fonts/shabnam/Shabnam-Light-FD.eot");
  src: url("../../public/fonts/shabnam/Shabnam-Light-FD.woff2") format("woff2"),
    url("../../public/fonts/shabnam/Shabnam-Light-FD.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: shabnam;
  font-style: normal;
  font-weight: 100;
  src: url("../../public/fonts/shabnam/Shabnam-Thin-FD.eot");
  src: url("../../public/fonts/shabnam/Shabnam-Thin-FD.woff2") format("woff2"),
    url("../../public/fonts/shabnam/Shabnam-Thin-FD.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: mona-sans;
  font-style: normal;
  font-weight: normal;
  src: url("../../public/fonts/mona-sans/Mona-Sans-Regular.woff")
    format("truetype");
}

@font-face {
  font-family: mona-sans-semibold;
  font-style: normal;
  src: url("../../public/fonts/mona-sans/Mona-Sans-SemiBold.woff")
    format("truetype");
}

@font-face {
  font-family: mona-sans-bold;
  font-style: normal;
  src: url("../../public/fonts/mona-sans/Mona-Sans-Bold.woff")
    format("truetype");
}

@font-face {
  font-family: iran-sans-bolder;
  font-style: normal;
  font-weight: bolder;
  src: url("../../public/fonts/mona-sans/Mona-Sans-Black.woff")
    format("truetype");
}