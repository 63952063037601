@import "./_fonts";
@import "./_variables";
@import "bootstrap/dist/css/bootstrap.css";
@import "./customize-bootstrap";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--app-font-family), "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif, iran-sans, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}
body {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin: 0;
  background-color: var(--theme-light);
}
a {
  color: inherit;
  text-decoration: none;
  border: 1px solid transparent;
}
button {
  &[disabled] {
    cursor: auto !important;
  }
}

.primary-btn {
  border: 1px solid var(--white);
  background-color: var(--primary);
  color: var(--white);
  font-size: var(--font-size-small);
  border-radius: 5px;
  padding: 4px 32px;
  &:hover {
    border: 1px solid var(--primary);
    background-color: var(--white);
    color: var(--primary);
  }
}
.primary-btn-outline {
  border: 1px solid var(--primary);
  background-color: var(--white);
  color: var(--primary);
  font-size: var(--font-size-small);
  border-radius: 54px;
  padding: 4px 32px;
  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
}

.button {
  &--striped {
    @include button--striped;
  }
}

.direction {
  &--rtl {
    direction: rtl;
  }

  &--ltr {
    direction: ltr;
  }
}

.blur-box {
  @include blur;
  border-radius: 15px;
}

.flex-center {
  @include flex-center;
}

h1 {
  font-size: var(--font-size-x-large);
  font-weight: bold;
  word-spacing: 0;
}
h2 {
  font-size: var(--font-size-large);
  font-weight: bold;
  word-spacing: 0;
}
h3 {
  font-size: var(--font-size-small);
  font-weight: bold;
  word-spacing: 0;
}
h4 {
  font-size: var(--font-size-x-small);
  font-weight: bold;
  word-spacing: 0;
}
h5 {
  font-size: var(--font-size-xx--small);
  font-weight: bold;
  word-spacing: 0;
}
p {
  font-size: var(--font-size-small);
}

.custom-box {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-50);
  background-color: var(--white);
}

.ws-pre-line {
  white-space: pre-line;
}



@media (max-width: 674px) {
  h1 {
    font-size: var(--font-size-x-large);
  }
  h2 {
    font-size: var(--font-size-medium);
  }
  h3 {
    font-size: var(--font-size-small);
  }
  h4 {
    font-size: var(--font-size-x-small);
  }
  h5 {
    font-size: var(--font-size-xx--small);
  }
  p {
    font-size: var(--font-size-small);
  }
}
