@import "../../styles/_variables";
.card {
  @include flex-center;
  position: relative;
  -webkit-box-shadow: inset 0px 0px 150px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: inset 0px 0px 150px 0px rgba(0, 0, 0, 0.35);
  box-shadow: inset 0px 0px 150px 0px rgba(0, 0, 0, 0.35);
  margin: 0 -5px;
  overflow: hidden;
  border-radius: 0;
  transition: 0.5s;
  width: 250px;
  height: 250px;
  border-radius: 12px;
  &:first-child {
    margin: 0;
  }
  &:hover {
    -webkit-box-shadow: 0px 0px 40px -17px rgb(0 0 0);
    -moz-box-shadow: 0px 0px 40px -17px rgb(0 0 0);
    box-shadow: 0px 0px 40px -17px rgb(0 0 0);
    transform: scale(1.1);
    width: 350px;
    border-radius: 12px;
    z-index: 100;
    span {
      display: none;
    }
    img {
      position: relative;
      width: 100%;
      left: unset;
      top: unset;
      z-index: 1;
      filter: blur(0px);
    }
  }
  span {
    text-shadow: 0px 0px 5px #ffffff;
    text-align: center;
    color: rgb(230, 230, 230);
    font-weight: 500;
    z-index: 1;
    padding: 1rem;
    font-size: var(--font-size-small);
  }
  img {
    filter: blur(8px);
    position: absolute;
    left: 10;
    top: 40;
    z-index: 0;
    width: 200px;
  }
}
.disableBlur {
  align-items: flex-end;
  padding: 12px;
  img {
    top: 24px;
    filter: blur(0px);
  }
}

@media (max-width: "674px") {
  .card {
    width: 100%;
    height: 100px;
    img {
      width: 100px;
    }
    &:hover {
      width: 100%;
      img {
        width: auto;
        height: 120%;
      }
    }
    span {
      max-width: 50%;
    }
  }
  .disableBlur {
    img {
      top: 10px;
      left: 0;
    }
    @include flex-center;
  }
}
