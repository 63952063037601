@import "./_variables";
.row {
  margin: 0;
  padding: 0;
}

.modal {
  direction: ltr;
  color: var(--theme-light);
  * {
    direction: rtl;
  }
}
.modal-header .btn-close {
  filter: brightness(100) invert(1);
  margin: 0;
  font-size: 12px;
  color: var(--theme-light);
}
.modal-content {
  background-color: var(--secondary);
  color: var(--theme-light);
}

.dropdown-item {
  all: unset;
  &:hover {
    all: unset;
  }
}

.nav-tabs {
  .nav-item {
    button {
      color: inherit;
    }
  }
}

.accordion-button,
.accordion-body {
  @include custom-box;
  &:active,
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.accordion-button {
  &:not(.collapsed) {
    border: none;
    color: var(--dark);
    background-color: var(--white);
  }
  &:after {
    display: none;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.fs-7 {
  font-size: 15px !important;
}

.fs-8 {
  font-size: 12px !important;
}

@media (max-width: "674px") {
  .fs-7 {
    font-size: 12px !important;
  }

  .fs-8 {
    font-size: 10px !important;
  }
}
