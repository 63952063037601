@import "../../styles/_variables";
.dropdown_container {
  @include flex-center;
  .dropdown_button {
    background-color: inherit !important;
    color: var(--black) !important;
    font-weight: normal !important;
    border: none;
  }
  .dropdown_menu {
    font-size: var(--font-size-x-small);
    padding: 0;
    border-radius: 4px;
    white-space: nowrap;
    background-color: var(--gray);
    *:nth-child(even) {
      background-color: var(--gray-50);
    }
    overflow: hidden;
  }
}
