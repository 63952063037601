@import "../../styles/variables";
.slide_item_container {
  border-radius: 5px;
  .slide_item {
    border-radius: 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
    text-shadow: 0px 0px 5px #ffffff;
    color: var(--white);
    font-weight: bold;
    font-size: var(--font-size-large);
    img,
    span {
      transition: 0.5s;
    }
    &:hover {
      img,
      span {
        transition: 0.5s;
        transform: scale(1.1);
      }
    }
  }
}
.products_name {
  .active {
    color: var(--info-hover) !important;
  }
  button {
    text-align: right;
    display: block;
    font-weight: bold;
    font-size: var(--font-size-medium);
    &:hover {
      color: var(--info);
    } 
    &[dir="ltr"] {
      text-align: left;
    }
  }
}

@media (max-width:"674px") {
  .slide_item_container{
    .slide_item{
      height: 20vh;
      padding: 12px;
      img{
        width: 50%;
        height: auto;
      }
    }
  }
  .products_name{
    button{
      font-size: var(--font-size-small);
    }
  }
}