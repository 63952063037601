:root {
  --app-font-family: mona-sans, shabnam, iran-sans;

  --black: rgb(0, 0, 0);
  --theme-dark: #696969;
  --theme-light: #ececec;
  --primary: #90884a;
  --secondary: #6a6641;
  --white: #fff;
  --dark: #212529;
  --error: #dc3545;
  --info: #0d6efd;
  --success: #25ce80;
  --success-50: #e6ffe0;
  --warning: #ffc800;
  --warning-50: #f0eddf;
  --blue: #0a53be;
  --info-hover: #0b5ed7;
  --gray-50: #f0f0f0;
  --gray: #dbdbdb;

  --font-size-x-large: 32px;
  --font-size-large: 24px;
  --font-size-medium: 20px;
  --font-size-small: 16px;
  --font-size-x-small: 14px;
  --font-size-xx-small: 12px;

  @media (max-width: 674px) {
    --font-size-x-large: 20px;
    --font-size-large: 18px;
    --font-size-medium: 16px;
    --font-size-small: 12px;
    --font-size-x-small: 10px;
    --font-size-xx-small: 8px;
  }
}

$light: #f3f3f3;

@mixin custom-box {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-50);
  background-color: var(--white);
}
@mixin blur {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

@mixin button--striped {
  color: inherit;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
