@import "../../styles/variables";

.textarea_field {
  width: 100%;
  color: var(--white);
  padding: 3px 7px;
  border: 1px solid var(--gray-50);
  background-color: var(--placeholder);
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
  font-size: var(--font-size-small);
  min-height: 150px;
  &:focus {
    border-color: var(--white);
  }

  &::placeholder {
    font-size: var(--font-size-xx-small);
    color: var(--gray);
  }
  &[disabled] {
    border: none;
    background-color: var(--placeholder);
    color: var(--theme-dark);
  }
}
.error {
  color: var(--error) ;
}
.input_error {
  border-color: var(--error) ;
}
